import React, { Component } from "react"

class ModalLarge extends Component {
  render() {
    return (
     
        <div className="modal-content-large">
          <span className="modal-close">&times;</span>
          <div className="modal-grid-container modal-content">
            <div className="modal-grid-item-left">
              <span className="modal-title">{this.props.modal.name}</span>
              <p className="modal-text">{this.props.modal.description}</p>
              <p className="modal-text">
                Completed: {this.props.modal.completed}
                <br />
                {this.props.modal.deployed && (
                  <a className="link" href={this.props.modal.deployed}>
                    See the deployed site
                    <br />
                  </a>
                )}
                {this.props.modal.github && (
                  <a className="link" href={this.props.modal.github}>
                    See the code
                    <br />
                  </a>
                )}
              </p>
              <br />
            </div>
            <div className="modal-grid-item-right">
              <img
                src={this.props.modal.image}
                alt={this.props.modal.name}
                className="modal-image"
              ></img>
            </div>
          </div>
        </div>
    
    )
  }
}
export default ModalLarge

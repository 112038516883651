import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import ModalLarge from "../components/Layout/modal-large"
import ModalSmall from "../components/Layout/modal-small"

class WorkPage extends Component {
  state = {
    smallScreen: false,
    modal: { name: "" },
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 840 })
  }

  openModal(e) {
    this.setState({ modal: e })
    document.getElementById("modal").style.display = "block"
  }

  closeModal() {
    document.getElementById("modal").style.display = "none"
  }

  render() {
    let workData = this.props.data.allWorkJson.nodes[0]
    let workItems = []
    let i = 0
    workData.work_items.forEach(item => {
      workItems.push(
        <div
          key={i}
          onClick={this.openModal.bind(this, item)}
          className={
            this.state.smallScreen ? "grid-item-small" : "grid-item-large"
          }
          style={{
            backgroundImage: `url(${item.image})`,
            backgroundSize: "100%",
          }}
        ></div>
      )
      i++
    })
    return (
      <Layout page={"work"}>
        <h1 className="title">{workData.title}</h1>
        <div className="text">{workData.text}</div>
        <div
          className={
            this.state.smallScreen
              ? "grid-container-small"
              : "work-grid-container"
          }
        >
          {workItems}
        </div>
        <div id="modal" className="modal" onClick={this.closeModal}>
          {this.state.smallScreen ? (
            <ModalSmall modal={this.state.modal} />
          ) : (
            <ModalLarge modal={this.state.modal} />
          )}
        </div>
      </Layout>
    )
  }
}
export default WorkPage

export const query = graphql`
  query {
    allWorkJson {
      nodes {
        id
        title
        description
        text
        work_items {
          name
          stack
          description
          completed
          image
          deployed
          github
        }
      }
    }
  }
`
